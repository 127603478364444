:root {
  --c-border: #000000;
  --c-panel-background: #FFFFFF;
  --c-disabled: #EEEEEE;
  --c-focus: #000000;
  --c-field-disabled: var(--c-disabled);
  --c-field-focus: var(--c-focus);
  --c-input-background: var(--c-panel-background);
  --c-input-border: var(--c-border);
}

.field.adaptive-placeholder {
  position: relative;
  margin-bottom: 0;
}
.field.adaptive-placeholder label {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 30px 16px 0;
  transition: all 0.3s;
  z-index: 1;
}
.field.adaptive-placeholder.field-error label, .field-error .field.adaptive-placeholder label {
  color: #B53638;
}
.field.adaptive-placeholder label ~ input.input,
.field.adaptive-placeholder label ~ div input.input {
  padding-top: 25px;
}
.field.adaptive-placeholder label ~ textarea.input,
.field.adaptive-placeholder label ~ div textarea.input {
  padding-top: 32px;
}
.field.adaptive-placeholder .select-replace__value {
  padding-top: 32px;
  line-height: 24px;
}

.field--address-lookup.adaptive-placeholder label {
  top: auto;
  bottom: 51px;
  padding: 0 16px;
}

.field--address-lookup.field--address-lookup-active.adaptive-placeholder label {
  bottom: 9px;
}

.field.adaptive-placeholder--active label {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  top: 9px;
  right: auto;
  left: 16px;
  padding: 0;
  transform: none;
}
.field.adaptive-placeholder--active.field-error label, .field-error .field.adaptive-placeholder--active label {
  color: #000000;
}
.field.adaptive-placeholder--active.field-error .input, .field-error .field.adaptive-placeholder--active .input {
  color: #B53638;
}

.field--address-lookup.adaptive-placeholder--active label {
  bottom: 80px;
}

.field--address-lookup.field--address-lookup-active.adaptive-placeholder--active label {
  bottom: auto;
}

.form .select-replace {
  margin: 0;
  padding: 0;
}
.form .field--permission label {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3333333333;
}

.form__title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.1875;
  margin-bottom: 40px;
}

@media screen and (max-width: 62.4375em) {
  .form__title--desktop-only {
    display: none;
  }
}

.form__small-title {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 12px;
}

.form__fieldset {
  max-width: 100%;
  margin-bottom: 40px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}

.form__fieldset--bottom-square {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.form__fieldset--flush {
  margin-bottom: 0;
}

.form__fieldset--tight {
  margin-bottom: 24px;
}

@media screen and (max-width: 62.4375em) {
  .form__fieldset--close {
    margin-bottom: 20px;
  }
}

.form__group {
  padding: 10px 15px;
}

.form__label {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  margin-bottom: 12px;
}

.form__terms {
  margin: 48px 0 32px;
}

.form__checkbox {
  margin-bottom: 24px;
  padding: 16px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}
.form__checkbox:last-child {
  margin-bottom: 0;
}
.form__checkbox label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2857142857;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.form__checkbox .check-replace {
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  margin-right: 0;
  margin-left: 40px;
}
.form__checkbox .check-replace svg {
  width: 16px;
  height: 16px;
}
.form__checkbox p.error {
  padding-left: 0;
}

.form__checkbox--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.form__toggle label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2857142857;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.form__toggle input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.form__toggle-status {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 45px;
  height: 24px;
  padding: 3px;
  border: 1px solid #000000;
  border-radius: 12px;
}
input:focus + .form__toggle-status {
  border-radius: 12px;
  outline: none;
  box-shadow: 0 0 0 1px #000000;
}
input:checked + .form__toggle-status {
  justify-content: flex-end;
  background: #000000;
}
.form__toggle-status:after {
  content: "";
  width: 18px;
  height: 18px;
  background: #FFFFFF;
  border-radius: 100%;
  border: 1px solid #000000;
}

.field-textarea--placeholder-bottom label {
  bottom: 10px;
  top: unset !important;
  color: #A2A2A2;
}
.field-textarea--placeholder-bottom label.error {
  padding-bottom: 33px;
}
.field-textarea--placeholder-bottom textarea {
  height: 130px;
  padding: 8px 15px 32px;
}

.field--asset-file {
  padding: 12px 15px 0;
}
.field--asset-file .file-overlay {
  display: flex;
  align-items: center;
}
.field--asset-file .files-list {
  margin-left: 10px;
}
.field--asset-file .button span {
  color: inherit;
  font-weight: 400;
}

.parsley-errors-list {
  font-size: 14px;
  font-size: 0.875rem;
  color: #B53638;
}
.parsley-errors-list:not(:empty) {
  padding: 5px 15px;
}
.field--permission .parsley-errors-list:not(:empty) {
  padding: 5px 0;
}