@import 'tools/mixins/_focus';
@import 'tools/mixins/_hide-safely';
@import 'tools/mixins/_margin';

.form {

    .select-replace {
        margin: 0;
        padding: 0;
    }

    .field--permission {

        label {
            @include h6;
        }
    }
}

    .form__title {
        @include h2;

        margin-bottom: 40px;
    }

    .form__title--desktop-only {
        @include breakpoint(tablet) {
            display: none;
        }
    }

    .form__small-title {
        @include font(20px, 24px);

        margin-bottom: 12px;
    }

    .form__fieldset {
        max-width: 100%;
        margin-bottom: 40px;
        border: 1px solid $c-border;
        border-radius: 4px;
    }

    .form__fieldset--bottom-square {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .form__fieldset--flush {
        margin-bottom: 0;
    }

    .form__fieldset--tight {
        margin-bottom: 24px;
    }

    .form__fieldset--close {

        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }
    }

        .form__group {
            padding: 10px 15px;
        }

        .form__label {
            @include h6;

            margin-bottom: 12px;
        }

    .form__terms {
        margin: 48px 0 32px;
    }

    .form__checkbox {
        @include margin(bottom, 24px);

        padding: 16px;
        border: 1px solid $c-border;
        border-radius: 4px;

        label {
            @include compact-body;

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
        }

        .check-replace {
            flex: 0 0 auto;
            width: 24px;
            height: 24px;
            margin-right: 0;
            margin-left: 40px;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        p.error {
            padding-left: 0;
        }
    }

    .form__checkbox--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .form__toggle {

        label {
            @include compact-body;

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
        }

        input {
            @include hideSafely();
        }
    }

        .form__toggle-status {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            width: 45px;
            height: 24px;
            padding: 3px;
            border: 1px solid $c-black;
            border-radius: 12px;

            input:focus + & {
                @include focus($borderRadius: 12px);
            }

            input:checked + & {
                justify-content: flex-end;
                background: $c-black;
            }

            &:after {
                content: '';
                width: 18px;
                height: 18px;
                background: $c-white;
                border-radius: 100%;
                border: 1px solid $c-black;
            }
        }
    .field-textarea--placeholder-bottom {
        label {
            bottom: 10px;
            top: unset !important;
            color: $c-grey-text;
        }
        label.error {
            padding-bottom: 33px;
        }
        textarea {
            height: 130px;
            padding: 8px 15px 32px;
        }
    }

    .field--asset-file {
        padding: 12px 15px 0;

        .file-overlay {
            display: flex;
            align-items: center;
        }

        .files-list {
            margin-left: 10px;
        }

        .button span {
            color: inherit;
            font-weight: 400;
        }
    }
