.field.adaptive-placeholder {
    position: relative;
    margin-bottom: 0;

    label {
        @include h4;

        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: 0;
        padding: 30px 16px 0;
        transition: all .3s;
        z-index: 1;
    }

    &.field-error, .field-error & {

        label {
            color: $c-error;
        }
    }

    label ~ input.input,
    label ~ div input.input {
        padding-top: 25px;
    }

    label ~ textarea.input,
    label ~ div textarea.input {
        padding-top: 32px;
    }

    .select-replace__value {
        padding-top: 32px;
        line-height: 24px;
    }
}

.field--address-lookup.adaptive-placeholder {

    label {
        top: auto;
        bottom: 42px + 9px;
        padding: 0 16px;
    }
}

.field--address-lookup.field--address-lookup-active.adaptive-placeholder {

    label {
        bottom: 9px;
    }
}

.field.adaptive-placeholder--active {

    label {
        @include h6;

        top: 9px;
        right: auto;
        left: 16px;
        padding: 0;
        transform: none;
    }

    &.field-error, .field-error & {

        label {
            color: $c-black;
        }

        .input {
            color: $c-error;
        }
    }
}

.field--address-lookup.adaptive-placeholder--active {

    label {
        bottom: 42px + 38px;
    }
}

.field--address-lookup.field--address-lookup-active.adaptive-placeholder--active {

    label {
        bottom: auto;
    }
}
