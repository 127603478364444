.parsley-errors-list {
    @include rem(14px);

    color: $c-error;

    &:not(:empty) {
        padding: 5px 15px;

        .field--permission & {
            padding: 5px 0;
        }
    }
}
